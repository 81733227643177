import React, { useEffect, useState } from 'react';
import { useLocation , useNavigate} from 'react-router-dom';
import axios from 'axios';
import '../Styles/Checkout.css'
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'

function generateId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 25 ; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }


const Checkout = () => {
    initMercadoPago('APP_USR-87499587-8504-410e-84c2-7d21fe327f81',{
        locale: "es-CL"
    });
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        fullname: '',
        phone_number: '+56',
        rut: '',
        address: '',
        address_second: '',
        address_third: '',
        email: '',
        extra_comments: '',
        quantity: 0,
        selected_date:'',
        event_name: '',
        total_price: 0,
        transaction_id: '',
        payment_status: 'pending',
        internalId:''
    });
    const [showAddressSecond, setShowAddressSecond] = useState(false)
    const [showAddressThird, setShowAddressThird] = useState(false)

    
    const location = useLocation();


    useEffect(() => {
        const transactionInternalId = generateId()
        setFormData(prevState => ({
            ...prevState,
            internalId: transactionInternalId
        }));
        console.log(location.state)
        const { quantity, selectedDate, eventName, totalPrice } = location.state || {};
        

        if (
                quantity === undefined ||
                quantity === undefined ||
                eventName === undefined    
            )
            {
                navigate('/events')
            }
        else {
            //console.log(quantity, eventName, totalPrice)
            setFormData(prevState => ({
                ...prevState,
                quantity: quantity
            }));
            setFormData(prevState => ({
                ...prevState,
                selected_date: selectedDate
            }));
            setFormData(prevState => ({
                ...prevState,
                event_name: eventName
            }));
            setFormData(prevState => ({
                ...prevState,
                total_price: totalPrice
            }));

            if (quantity>2){
                setShowAddressSecond(true)
            } 
            if (quantity>4) {
                setShowAddressThird(true)
            }

        }

        }, []);
    

    const [showMP, setShowMP] = useState(false)

    const createPreference = async () => {
        try {
        console.log(formData)
        const response = await axios.post("https://articles-tr7ecrkimq-uc.a.run.app/create_preference", {
            title: "Traslado de "+formData.quantity + " pasajeros a "+ formData.event_name,
            quantity: 1,
            price: formData.total_price,
            name: formData.fullname,
            internalId: formData.internalId
            });
        console.log(response)
        const { id } = response.data;
        return id;

        } catch (error) {
              console.log(error);
        }
      };

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
        
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
    
        const form = document.querySelector('form');  
        if (form.reportValidity()) {
            const id = await createPreference();

            setFormData(prevState => ({
                ...prevState,
                transaction_id: id
            }));

            setShowMP(true)
        }
    };
    const handleTransactionData = async  (e) => {
        e.preventDefault();
        
        try {
            const response = await axios.post('https://articles-tr7ecrkimq-uc.a.run.app/add_transaction', formData);
            
        } catch (error) {
            console.error('Error adding transaction:', error.response ? error.response.data : error.message);
        }
    };



    return (
    <div className='checkout'>
        <div className="checkout-container">
        {/* Resumen de compra */}
            <div className="summary-section">
                <h1 className="summary-title">Resumen de la compra</h1>
                <div className="summary-details">
                    <p><strong>Evento:</strong> {formData.event_name}</p>
                    <p><strong>Fecha:</strong> {formData.selected_date}</p>
                    <p><strong>Cantidad de asientos:</strong> {formData.quantity}</p>
                    <p><strong>Total:</strong> ${formData.total_price}</p>
                </div>
            </div>

        {/* Formulario de checkout */}
            <h1 className="checkout-title">Detalles del comprador</h1>
            <form className="checkout-form" onSubmit={handleSubmit} >
                <div className="form-group">
                    <label htmlFor="fullname">Nombre completo  <strong style={{color:'red'}}>*</strong></label>
                    <input
                    type="text"
                    id="fullname"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                    placeholder='Nombre Apellido'
                    required
                />
                </div>

                <div className="form-group">
                    <label htmlFor="phone_number">Número de teléfono  <strong style={{color:'red'}}>*</strong></label>
                    <input
                        type="tel"
                        id="phone_number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        required
                        pattern="^\+56\d{9}$"
                        title="El número de teléfono debe empezar con +56 y tener 9 dígitos adicionales."
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Correo electrónico <strong style={{color:'red'}}>*</strong></label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder='hola@tucorreo.cl'
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        title="Introduce un correo electrónico válido."
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Dirección de recogida  <strong style={{color:'red'}}>*</strong></label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        placeholder='Calle 123, Comuna'
                        required
                    />
                </div>
                
                {
                    showAddressSecond ? 
                        <div className="form-group">
                            <label htmlFor="address_second"> Segunda Dirección de recogida <em style={{fontSize: '12px'}}>(Opcional)</em> </label>
                            <input
                                type="text"
                                id="address_second"
                                name="address_second"
                                value={formData.address_second}
                                onChange={handleChange}
                                placeholder='Calle 123, Comuna'
                            />
                        </div>
                        :
                        <div></div>
                }
                {
                    showAddressThird ? 
                        <div className="form-group">
                            <label htmlFor="address_third"> Tercera Dirección de recogida <em style={{fontSize: '12px'}}>(Opcional)</em> </label>
                            <input
                                type="text"
                                id="address_third"
                                name="address_third"
                                value={formData.address_third}
                                onChange={handleChange}
                                placeholder='Calle 123, Comuna'
                            />
                        </div>
                        :
                        <div></div>
                }

                <div className="form-group">
                    <label htmlFor="extra_comments">Necesidades especiales / comentarios</label>
                    <textarea
                        id="extra_comments"
                        name="extra_comments"
                        value={formData.extra_comments}
                        onChange={handleChange}
                        rows="4"
                    />

                </div>

                {
                    showMP ?
                    <div onClick={handleTransactionData}>
                        <Wallet 
                            initialization={{ preferenceId: formData.transaction_id }} 
                            customization={{ texts:{ valueProp: 'security_safety'}}} 
                        /> 
                    </div>:
                    <div>
                        <button type='submit' className='button-payment' >Realizar Pago</button>
                        <p style={{fontSize: '12px', marginTop:'8px'}}>🔒Pagos seguros by Mercado Pago</p>
                    </div>
                }
            </form>
        </div>
        
    </div>
    );
};

export default Checkout;

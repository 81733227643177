import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import '../Styles/EventDetail.css'

import HeaderBar from '../Containers/HeaderBar';
import Footer from '../Containers/Footer';
import CardSafari from '../Containers/CardSafari';

import { initMercadoPago } from '@mercadopago/sdk-react'



const EventDetail = () => {
  initMercadoPago('APP_USR-87499587-8504-410e-84c2-7d21fe327f81',{
    locale: "es-CL"
  });
  const navigate = useNavigate();
  const howItWorksRef = useRef(null);
  const { event_id } = useParams(); // Get the event_id value from the URL
  const [event, setEvent] = useState(null);
  const [preferenceId, setPreferenceId] = useState(null)
  const [selectedSeats, setSelectedSeats] = useState(1);
  const [finalPrice, setFinalPrice] = useState(0)
  const [selectedDate, setSelectedDate] = useState('');


  const handleBuy = async () => {
    try {

        navigate('/checkout', {
          state: {
            quantity: selectedSeats,
            selectedDate:selectedDate,
            eventName: event.event_name,
            totalPrice: finalPrice
          }
        });
      
      
    } catch (error) {
      // Catch any errors that occurred during the createPreference() call
      console.error("Error creating preference:", error);
      alert("Ocurrió un error ¿Podrías intentar de nuevo?🙏");
    }
  };
  

  useEffect(() => {
    const seatOptions = [
      { seats: 1, price: 16990 },
      { seats: 2, price: 29990 },
      { seats: 3, price: 43990 },
      { seats: 4, price: 57990 },
      { seats: 5, price: 71990 },
      { seats: 6, price: 85990 },
      { seats: 7, price: 99990 } // Full van price
   ];
    const selectedOption = seatOptions.find((option) => option.seats === selectedSeats);
    if (selectedOption) {
      setFinalPrice(selectedOption.price);
    }
  }, [selectedSeats]);






  
  const handleSelectedDate = (e) => {
    setSelectedDate(e.target.value);
  };




  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`https://articles-tr7ecrkimq-uc.a.run.app/events/${event_id}`); // Fetch event details using event_id
        setEvent(response.data);
        setSelectedDate(response.data.date[0])
      } catch (error) {
        console.error('Error fetching event details', error);
      }
    };

    fetchEvent();
  }, [event_id]); // Effect runs whenever event_id changes

  if (!event) {
    return <div></div>;
  }


  const handleScrollHowItWorks = () => {
    if (howItWorksRef.current) {
    howItWorksRef.current.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
        window.scrollBy({ top: -200, behavior: 'smooth' }); 
        }, 1000); 
    }
};


  return (
    <div className='event-detail-page'>
      <div className='home-header'> 
          <HeaderBar onScrollHowItWorks={handleScrollHowItWorks} />
      </div>
      
      <div className="event-detail">
        <h1>{event.event_name}</h1>
        <p><strong>📅 {event.date_formatted}</strong></p>
        <p><strong>📍 {event.location}</strong></p>
        <p> Disfruta al máximo del concierto <strong> sin pensar en manejar, estacionamiento o como devolverte. </strong><br /> <strong>Te llevamos ida y vuelta</strong> con la misma pasión y cuidado que sientes por la música.🎶</p>
        <img src={event.event_img} alt={event.event_name} />
        <p>{event.event_description}</p>

        <div className="seat-selector">
          <h2>Reserva tu traslado✨</h2>
          <div className="form-group">

            <label htmlFor="dateSelect">Fecha a reservar</label>
            <select 
              value={selectedDate}
              onChange={handleSelectedDate}
            >
              {event.date.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>


            <label htmlFor="seatSelect">Número de Asientos</label>
            <select
              id="seatSelect"
              name="seats"
              value={selectedSeats}
              onChange={(e) => setSelectedSeats(Number(e.target.value))}
            >
              <option value="1">1 Asiento ($16.990)</option>
              <option value="2">2 Asientos ($29.990) 12% Desc</option>
              <option value="3">3 Asientos ($43.990) 13% Desc</option>
              <option value="4">4 Asientos ($57.990) 14% Desc</option>
              <option value="5">5 Asientos ($71.990) 15% Desc</option>
              <option value="6">6 Asientos ($85.990) 16% Desc</option>
              <option value="7">7 Asientos ($99.990) ⭐Traslado Privado⭐ 16% Desc</option>
            </select>

          </div>

          <div className="price-display">
            <p>
              Precio total:{' '}
              <strong>
                ${finalPrice.toLocaleString()} 
              </strong>
            </p>
          </div>
          <button className='buy-button' onClick={handleBuy} >⭐Reservar</button> <br />
          <div className='type-of-trip'>
            {selectedSeats < 7 ? 
              <em>*Traslado compartido </em> 
              : 
              <em>*Traslado privado </em> 
            }
          </div>
      </div>
      
      <div ref={howItWorksRef} className='how-it-works' >
          <CardSafari mode="light" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EventDetail;
